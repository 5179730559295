.modification-summary {
    margin-top: 2rem;
    text-align: center;
    ul {
        list-style: none;
        margin: 0;
        width: 100%;
        padding-left: 0;
        text-align: center;
    }
    strong {
        color: $base-font-color;
    }
    &__row {
        margin-bottom: 4rem;
        &:last-child {
            margin-bottom: 0;
        }
    }
}
