.block-featured-news {
    margin: auto;
    text-align: center;
    max-width: $width-small;
    &__content {
        margin-bottom: $base-spacing * 2;
    }
}
.featured-news-list {
    list-style: none;
    padding: 0;
    margin: 0 0 $base-spacing 0;
    &__date {
        font-size: 2rem;
        font-weight: $weight-light;
    }
    &__publication {
        font-size: 2.2rem;
        font-weight: $weight-medium;
    }
    &__link {
        font-size: 2.4rem;
        a {
            color: $c-black;
        }
    }
}
