.block-wash-green {
    &__content {
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            @include section-subtitle;
        }
    }
}
