/* Buttons */
.button-row {
    margin: $base-spacing 0;
    .button {
        margin: 0 5px 5px 0;
    }
    .forgot-password {
        display: block;
        margin-top: $base-spacing/2;
        margin-bottom: 0;
        font-size: 2rem;
        color: $base-font-color;
        letter-spacing: 0.1rem;
        text-align: center;
    }
    &--no-margin {
        margin: 0;
    }
    &--extra-margin-top {
        margin: $section-spacing/2 0 0 0;
    }
    &--extra-margin-bottom {
        margin-bottom: $section-spacing;
    }
    &--section-margin {
        margin: $section-spacing 0;
    }
    &--section-margin-half {
        margin: $section-spacing/2 0;
    }
    &--center {
        text-align: center;
    }
    &--block {
        .button {
            margin: 0.5rem auto;
            display: block;
            width: 100%;
            max-width: 320px;
        }
    }
    &--block-form {
        .button {
            margin: 0.5rem auto;
            display: block;
            width: 100%;
            max-width: 460px;
        }
    }
    &--return {
        margin-top: $section-spacing;
        padding-top: $section-spacing/2;
        border-top: 2px solid $base-border-color;
        display: flex;
        align-items: center;
        flex-direction: column-reverse;
        a {
            color: $base-font-color;
            letter-spacing: $letter-spacing-small;
            display: block;
            margin: $base-spacing/2 0;
        }
        @include medium {
            flex-direction: row;
            justify-content: space-between;
        }
    }
}

a.button {
    color: #fff;
    &.block-hide-show__trigger:before {
        color: #fff;
    }
}
.button {
    appearance: none;
    background: $button-color;
    border: 0;
    border-radius: $base-border-radius;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-family: $base-font-family;
    font-size: $base-font-size;
    -webkit-font-smoothing: antialiased;
    font-weight: $weight-regular;
    letter-spacing: 0.2rem;
    line-height: 1;
    padding: ($base-spacing / 1.5) $base-spacing;
    text-decoration: none;
    transition: background-color $base-duration $base-timing, opacity $base-duration $base-timing;
    user-select: none;
    vertical-align: middle;
    text-align: center;
    &--small {
        font-size: 1.9rem;
        padding: 1rem;
    }
    &--light-blue {
        background: $c-blue-light;
    }
    &--icon {
        padding-left: 4rem;
    }
    &--offsite,
    &[target='_blank'] {
        padding-right: 5rem;
        background-image: url(/images/icon-offsite.svg);
        background-size: 2rem 2rem;
        background-position: right 2rem center;
        background-repeat: no-repeat;
    }
    &--market {
        background-image: url(/images/logo-bear.svg);
        background-size: 4rem 4rem;
        background-position: left 1.5rem center;
        background-repeat: no-repeat;
        border-radius: 3rem;
        padding-left: 7rem;
        span {
            border: 2px solid $c-green;
            font-weight: $weight-medium;
            padding: 0.5rem;
            display: inline-block;
            background: $c-green-dark;
        }
    }
    &--star {
        background-image: url(/images/icon-starWhite.svg);
        background-size: 2rem 2rem;
        background-position: left 1rem center;
        background-repeat: no-repeat;
    }
    &--chat {
        background-image: url(/images/img-speechBubbleWhite.svg);
        background-size: 2rem 2rem;
        background-position: left 1rem center;
        background-repeat: no-repeat;
    }
    &--key-large {
        span {
            display: inline-block;
            position: relative;
            &:before {
                content: '';
                height: 1.75rem;
                width: 3rem;
                display: block;
                background-image: url(/images/icon-key-white.svg);
                background-size: contain;
                background-position: center center;
                background-repeat: no-repeat;
                position: absolute;
                left: -4rem;
            }
        }
    }
    &--wide {
        width: 100%;
        max-width: 360px;
    }

    &--cancel {
        background: $c-gray-medium;
    }

    &--clicked {
        opacity: 0.5;
        outline: 0;
    }

    &:hover,
    &:focus {
        //background-color: shade($action-color, 20%);
        color: #fff;
    }

    &:disabled {
        cursor: not-allowed;
        opacity: 0.5;
    }
}
