.block-wash-features {
    border-top: 1px solid $base-border-color;
    border-bottom: 1px solid $base-border-color;
    padding: $base-spacing 0;
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        @include section-subtitle;
        margin-bottom: 0;
    }
    p {
        margin-top: 0;
    }
}
