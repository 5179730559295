/* Error pages */
.error {
    .code {
        border-right: 2px solid;
        font-size: 26px;
        padding: 0 15px 0 15px;
        text-align: center;
    }

    .message {
        padding: 10px;
        font-size: 18px;
        text-align: center;
    }
}
