// Container
@mixin container {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    max-width: $width-base; // 1045px
}

@mixin wrapper {
    padding-left: 2rem;
    padding-right: 2rem;
}

@mixin clean-list {
    list-style: none;
    padding: 0;
    margin: 0;
}

@mixin group {
    &:after {
        content: '';
        display: table;
        clear: both;
    }
}

@mixin small {
    @media (min-width: 480px) {
        @content;
    }
}
@mixin medium {
    @media (min-width: 640px) {
        @content;
    }
}
@mixin medium-or-less {
    @media (max-width: 640px) {
        @content;
    }
}
@mixin medium-large {
    @media (min-width: 800px) {
        @content;
    }
}
@mixin large {
    @media (min-width: 1045px) {
        @content;
    }
}
@mixin xl {
    @media (min-width: 1280px) {
        @content;
    }
}
@mixin xxl {
    @media (min-width: 1800px) {
        @content;
    }
}

// Font mixins to help with WYSIWYG requirements

@mixin page-title {
    color: $c-blue-dark;
    font-size: 3.4rem;
    letter-spacing: 0.1rem;
}
@mixin section-title {
    font-size: 3rem;
    font-weight: $weight-medium;
    letter-spacing: $base-letter-spacing;
}
@mixin section-subtitle {
    font-size: 2.6rem;
    letter-spacing: 0.25rem;
    font-weight: $weight-medium;
}
@mixin paragraph {
    font-size: 2rem;
    margin-top: 0;

    &.margined {
        margin-top: $section-spacing;
        margin-bottom: $section-spacing;
    }
    &.margined-top {
        margin-top: $section-spacing;
    }
    &.margined-bottom {
        margin-bottom: $section-spacing;
    }
}
