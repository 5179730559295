/* Variables */

$base-font-family: 'Roboto', sans-serif;

/* Colors */
$c-black: #000000;
$c-white: #ffffff;
$c-gray: #cccccc;
$c-gray-light: #f2f2f2;
$c-gray-medium: #666666;
$c-blue-dark: #004b8d;
$c-blue-light: #2e99cf;
$c-green: #a6ce39;
$c-green-dark: #68903d;
$c-red: #990000;
$c-orange: #fdbe57;

$c-blue-bgr: #eaf5fa;
$c-tan-bgr: #fefbd9;
$c-green-bgr: #f3f7d5;
$c-gray-bgr: #f9f7f7;
$c-gray-dark-bgr: #ececec;

$weight-bold: 700;
$weight-medium: 500;
$weight-regular: 400;
$weight-light: 300;

$gutter-padding: 2rem;
$base-font-size: 2rem;
$base-font-color: $c-black;
$base-spacing: 2rem;
$section-spacing: 6rem;
$base-background-color: $c-white;
$base-border-color: $c-gray;
$base-border: 1px solid $base-border-color;
$base-border-thick: 2px solid $base-border-color;
$base-border-radius: 2px;
$link-color: $c-blue-dark;
$button-color: $c-blue-dark;
$placeholder-color: $c-gray;
$base-letter-spacing: 0.25rem;
$letter-spacing-small: 0.15rem;

$width-base: 104.5rem; // 1045px
$width-xsmall: 46rem; // 460px
$width-small: 68rem; // 680px
$width-medium: 84rem; // 680px
$width-xl: 128rem; // 680px

// Forms
$form-box-shadow: inset 0 1px 3px rgba(#000, 0.06);
$form-box-shadow-focus: $form-box-shadow,
    0 0 5px adjust-color($c-blue-dark, $lightness: -5%, $alpha: -0.3);
$form-field-width: 46rem;

// Error
$error-color: $c-red;
$warning-color: $c-orange;
$success-color: $c-blue-dark;

// Animations
$base-duration: 150ms;
$base-timing: ease;
